<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <v-snackbar
      v-model="snackbar"
      xs12
      multi-line
      :timeout="timeout"
      top
      color="orange"
    >
      {{ alertmessage }}
      <v-btn
        color="white"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
    <v-row justify="center pb-16">
      <v-col
        cols="12"
        sm="11"
        md="11"
      >
        <base-material-card
          icon="mdi-file-document-outline"
          color="primary"
        >
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              Quotes
            </div>
          </template>
          <div>
            <v-card
              class="d-flex align-content-space-between flex-wrap"
              color="transparent"
              flat
              tile
              min-height="200"
            >
              <v-card
                v-for="(policy, index) in userpolicies"
                :key="'policy' + index"
                class="ma-2"
                :max-width="mobileDevice ? 260 : 320"
                outlined
                tile
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">
                      {{ policy.policytype }}
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                      {{ format_amt(policy.policyvalue) }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ policy.termlength }} Years</v-list-item-subtitle>
                    <v-list-item-subtitle>Created On: {{ format_date(policy.timestamp) }} </v-list-item-subtitle>
                    <v-list-item-subtitle>Provider: {{ policy.provider.provider }}</v-list-item-subtitle>
                    <v-list-item-subtitle>Status: Quote</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-avatar
                    tile
                    size="80"
                    color="blanketbutton"
                    style="border-radius: 5px;"
                  >
                    <v-icon
                      color="white"
                    >
                      mdi-bank
                    </v-icon>
                  </v-list-item-avatar>
                </v-list-item>

                <v-card-actions>
                  <v-btn
                    v-if="policy.provider === 'Symetra'"
                    outlined
                    text
                    color="blanketbutton"
                    @click="process_app(policy)"
                  >
                    Symetra Application
                  </v-btn>
                  <v-btn
                    v-if="policy.provider.provider === 'Lincoln'"
                    outlined
                    text
                    color="blanketbutton"
                    @click="process_app(policy)"
                  >
                    Lincoln Application
                  </v-btn>
                  <v-btn
                    v-if="policy.provider.provider === 'Anico'"
                    outlined
                    text
                    color="blanketbutton"
                    @click="process_app(policy)"
                  >
                    Anico Application
                  </v-btn>
                  <v-btn
                    v-if="policy.provider.provider === 'Protective'"
                    outlined
                    text
                    color="blanketbutton"
                    @click="process_app(policy)"
                  >
                    Protective Application
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    color="blanketbutton"
                    text
                  >
                    <v-icon
                      color="primary"
                      size="26"
                      class=""
                      @click="deleteItem(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-card>
          </div>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import moment from 'moment'
  var numeral = require('numeral')

  export default {
    name: 'UserProfile',

    data () {
      return {
        snackbar: '',
        timeout: 5000,
        alertmessage: '',
        currentuser: {
          email: '',
          id: '',
          alerts: '',
          status: '',
          firstname: 'unset',
          lastname: 'unset',
          mobile: 'unset',
          address: 'unset',
          city: 'unset',
          country: 'USA',
          postcode: 'unset',
        },
        tabs: 0,
        list: {
          0: false,
          1: false,
          2: false,
        },
      }
    },
    computed: {
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      userpolicies () {
        let policies = []
        policies = this.$store.getters.getuserpolicies
        return policies
      },
    },
    created () {
      this.$store.dispatch('loaduserpolicies')
      this.$store.dispatch('loadAnicoAppQuestions')
      this.$gtag.pageview('UserPolicies')
      this.currentuser = this.$store.getters.getuser
    },

    methods: {
      updateuser () {
        this.$store.dispatch('updateUserProfile', this.currentuser)
        this.alertmessage = 'Profile has been updated'
        this.snackbar = true
      },
      format_amt (amt) {
        return numeral(amt).format('$0,000')
      },
      format_date (timestamp) {
        return moment(timestamp).format('YYYY-MM-DD')
      },
      process_app (policy) {
        let providername = policy.provider
        console.log(policy)
        this.$gtag.event('StartTermApplication' + '_' + providername)
        console.log('policy route ', policy)
        if (providername === 'Symetra') {
          this.$router.push('/symetratermlifeapp/' + policy.key)
        } else if (providername === 'Anico') {
          this.$router.push('/anicotermlifeapp/' + policy.key)
        } else {
          this.alertmessage = 'Route to ' + providername
          this.snackbar = true
        }
      }

    },
  }
</script>
